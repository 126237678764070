.form-control {
    height: 34px;
}

.register {
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.register-left {
    text-align: center;
    color: #fff;
    margin-top: 4%;
}

.register-right {
    background: #f8f9fa;
}

.register-left img {
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 1s infinite alternate;
}

.register-left p {
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}

.register .register-form {
    padding: 10% 4%;
    margin-top: 10%;
}

.register-heading {
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
    font-size: 30px;
    font-weight: 500;
}

.font-w400 {
    font-weight: 400;
}

.tab {
    margin-top: -20px;
    padding: 0px 4%;
}

.submit {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    width: 100px;
    height: 36px;
    font-size: 16px;
}

.tableScroll {
    height: 300px;
    overflow: scroll;
}
