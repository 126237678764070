@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Merienda+One&display=swap);
.button_error{
    background-color: #bf925b !important;
    border: 2px solid #000000 !important;
    color: #ffffff !important;
    transition: all .5s !important;
    font-family: "Josefin Sans", Arial, sans-serif;
    padding: 3px 20px !important;
}
.button_error:hover{
    background-color: #000000 !important;
    border: 2px solid  #bf925b !important;
}
/*----------------- common start ------------------*/

body {
    /*font-family: 'Merienda One', cursive;*/
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #0A0A0A;
}

.container {
    max-width: 1200px;
    padding: 0 15px;
}

a:hover,
a:focus {
    text-decoration: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

/*----------------- common end ------------------*/

/*----------------- header start ------------------*/

.main-logo {
    display: block;
    font: normal normal 40px 'Merienda One', Arial, cursive;
    color: rgba(0, 34, 252, 1);
}

.main-menu a {
    font: normal bold 16px 'Merienda One', Arial, cursive;
    color: #2c2c2c;
    padding: 0 0 0 20px;
    text-transform: uppercase;
}

header {
    padding: 16px 0;
}

.main-slider .slider-item h1 {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: rgba(0, 34, 252, 1);
    font: normal normal 60px 'Merienda One', Arial, cursive;
}

.main-slider .slider-item {
    padding: 50px 0;
}

.main-menu a:hover,
.main-menu a.active {
    color: #0da6a3;
}

/*----------------- header end ------------------*/

/*----------------- Slider arrow start ------------------*/

.slick-arrow {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    font-size: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 30px;
    z-index: 3;
}

.slick-next.slick-arrow {
    left: auto;
    right: 15px;
}

.slick-arrow::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-top: -16px;
    font-size: 16px;
    color: #FFF;
    content: "\f053";
    z-index: 3;
}

.slick-next.slick-arrow::after {
    content: "\f054";
}

.main-slider .slider-item h2 {
    color: rgba(250, 0, 12, 1);
    font: normal normal 60px 'Merienda One', Arial, cursive;
}

.main-slider .slider-item p {
    font: normal normal 24px 'Merienda One', Arial, cursive;
    color: rgba(101, 196, 247, 1);
}

.service img {
    width: 100%;
    max-height: 255px;
}

.service h1 {
    display: block;
    text-align: center;
    font: normal normal 24px 'Merienda One', Arial, cursive;
    margin: 15px 0 0;
}

.home-bottom-about p {
    color: rgba(0, 0, 0, 1);
    font: normal normal 24px 'Merienda One', Arial, cursive;
}

.home-bottom-about p a {
    color: rgba(247, 7, 7, 1);
    text-decoration: underline;
}

#gallery img {
    width: 100%;
    margin: 0 0 30px;
}

.pointer {
    cursor: pointer;
}

/*----------------- Slider arrow end ------------------*/

/*----------------- sofina about start ------------------*/

.main-title {
    color: rgba(0, 34, 252, 1);
    font: normal normal 35px 'Merienda One', Arial, cursive;
    margin: 0 0 30px;
}

.sofina-about p {
    margin: 0 0 5px;
}

.sofina-about a {
    display: block;
    color: #0071C2;
    font-weight: 700;
    text-decoration: underline;
    margin: 0 0 5px;
}

.main-title.black-title {
    color: #262626;
}

.sofina-about img {
    width: 100%;
}

/*----------------- sofina about end ------------------*/

/*----------------- product slider start ------------------*/
.slider-nav .slick-arrow::after {
    color: #262626;
}

.main-product .container {
    max-width: 320px;
    margin: 0 auto;
}

.main-product img {
    width: 100%;
}

@media (min-width: 480px) {
    .main-product .slick-arrow {
        left: -25px;
    }

    .main-product .slick-next.slick-arrow {
        right: -25px;
        left: auto;
    }
}

/*----------------- product slider end ------------------*/

/*----------------- about us start ------------------*/

.breadcrumb {
    background: transparent url("/assets/images/bredcrumb.jpg") no-repeat fixed center center/cover;
    padding: 125px 0;
}

.breadcrumb h1 {
    margin: 0;
    text-align: center;
    color: #fff;
    font: normal normal 35px 'Merienda One', Arial, cursive;
}

.inner-about-img {
    min-height: 370px;
}

.professional-title {
    position: relative;
    padding: 30px 0 0;
}

.professional-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 0;
}

/*----------------- about us end ------------------*/

/*----------------- bottom about start ------------------*/

.bottom-about {
    padding: 20px 0;
    background: transparent url("/assets/images/about-bg.jpg") no-repeat center center/cover;
}

.bottom-about .container {
    max-width: 640px;
}

.bottom-about p {
    margin: 0;
}

/*----------------- bottom about end ------------------*/

/*----------------- footer start ------------------*/

footer {
    background-color: rgba(26, 145, 119, 0.78);
    padding: 15px 0;
}

.map-img {
    width: 100%;
    max-height: 243px;
}
.fs-14 {
    font-size: 13px;
}
footer h2 {
    font: normal normal 24px 'Merienda One', Arial, cursive;
    margin: 0 0 15px;
}

.footer-img {
    width: 160px;
    height: 160px;
    margin: 0 15px 0 0;
}

.footer-address a {
    color: #1B7BBF;
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
}

.footer-link a {
    padding: 0 0 0 3px;
}

/*----------------- footer end ------------------*/

/*----------------- responsive start ------------------*/
.wb_cont_inner {
    max-width: 1140px;
    margin: 0 auto;
}
@media (max-width: 767px) {

    .main-logo {
        font: normal normal 15px 'Merienda One', Arial, cursive;
    }

    .main-menu a {
        font: normal bold 10px 'Merienda One', Arial, cursive;
        padding: 0 0 0 10px;
    }

    .main-slider .slider-item h1 {
        font: normal normal 20px 'Merienda One', Arial, cursive;
    }

}

@media (max-width: 479px) {
    .main-slider .slider-item h2 {
        font: normal normal 40px 'Merienda One', Arial, cursive;
    }
}

/*----------------- responsive end ------------------*/

/*updates 21/10*/
.product-title {
    font-size: 20px;
    font-weight: 700;
}

.product-img {
    height: 226px;
}

.paid-text h1 {
    margin: 30px 0 50px;
    text-align: center;
    color: #fff;
    background: #0056b3;
    padding: 10px 0;
}

.thank-text {
    font-size: 20px;
    padding: 0 10px;
}

.return-page {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    display: block;
    margin: 0 0 30px;
}

.form-control {
    height: 34px;
}

.register {
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.register-left {
    text-align: center;
    color: #fff;
    margin-top: 4%;
}

.register-right {
    background: #f8f9fa;
}

.register-left img {
    margin-top: 15%;
    margin-bottom: 5%;
    width: 25%;
    -webkit-animation: mover 2s infinite alternate;
    animation: mover 1s infinite alternate;
}

.register-left p {
    font-weight: lighter;
    padding: 12%;
    margin-top: -9%;
}

.register .register-form {
    padding: 10% 4%;
    margin-top: 10%;
}

.register-heading {
    text-align: center;
    margin-top: 8%;
    margin-bottom: -15%;
    color: #495057;
    font-size: 30px;
    font-weight: 500;
}

.font-w400 {
    font-weight: 400;
}

.tab {
    margin-top: -20px;
    padding: 0px 4%;
}

.submit {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    width: 100px;
    height: 36px;
    font-size: 16px;
}

.tableScroll {
    height: 300px;
    overflow: scroll;
}

